import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { TbMessage2Check, TbMessage2Plus } from "react-icons/tb";
import defaultProfile from '../../../assets/images/profile.png';
import { FaLongArrowAltLeft, FaSearch } from 'react-icons/fa';
import HeaderSubject from '../../../components/HeaderSubject';
import { AuthContext } from '../../../contexts/auth';
import { Image, Spinner } from "react-bootstrap";
import Header from '../../../components/Header';
import { IoMdSend } from "react-icons/io";
import api from '../../../services/api';
import { toast } from "react-toastify";
import ReactPlayer from 'react-player';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import './styles.css';

const Lession = () => {
    const { user } = useContext(AuthContext);
    const { idLession } = useParams();
    const [loading, setLoading] = useState(false);
    const [loadingForum, setLoadingForum] = useState(false);
    const [lastTopic, setLastTopic] = useState(null);
    const [showQuiz, setShowQuiz] = useState(false);
    const [showMarks, setShowMarks] = useState(false);
    const [marks, setMarks] = useState(null);
    const [subject, setSubject] = useState(null);
    const [lession, setLession] = useState(null);
    const [active, setActive] = useState(null);
    const [summary, setSummary] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [userAnswers, setUserAnswers] = useState({});
    const [showSummary, setShowSummary] = useState(false);
    const toggle = () => setShowSummary(!showSummary);
    const [doubts, setDoubts] = useState([]);
    const [doubt, setDoubt] = useState(null);
    const [addDoubt, setAddDoubt] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [answer, setAnswer] = useState('');
    const [filteredDoubts, setFilteredDoubts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const showTopic = (currentTopic) => {
        console.log(currentTopic)
        for (let i = 0; i < summary.length; i++) {
            if (summary[i].id === currentTopic.id) {
                setActive(currentTopic);
                setShowQuiz(true);
                setShowMarks(false);
            }
        }
        if (lastTopic.id === lession.id) {
            setShowQuiz(true);
        } else {
            setShowQuiz(false);
        }
    }

    const handleAnswerChange = (questionId, answer) => {
        setUserAnswers(prevState => ({
            ...prevState,
            [questionId]: answer
        }));
    };

    const handleSubmit = () => {
        setLoading(true);
        if (Object.keys(userAnswers).length === questions.length) {
            api.post('/students/quiz/answers', { answers: userAnswers })
                .then(response => {
                    console.log('/students/quiz/answers', response.data);
                    setLession(response.data.lession);
                    getMarks();
                })
                .catch(error => {
                    console.error(error.response);
                })
                .finally(() => setLoading(false));
        } else {
            toast.error('Por favor, responda todas questões');
            setLoading(false);
        }
    };

    const handleShowQuiz = () => {
        if (lession.status === 'Concluída') {
            getMarks();
        } else {
            setShowQuiz(true);
        }
    };

    const getMarks = () => {
        api.post('/students/quiz/marks', { lession: lession.id })
            .then(response => {
                console.log('/students/quiz/marks', response.data);
                setMarks(response.data);
                setShowQuiz(false);
                setShowMarks(true);
            })
            .catch(error => {
                console.error(error.response);
            });
    };

    const handleReplyQuiz = () => {
        setUserAnswers({});
        setShowQuiz(true);
        setShowMarks(false);
    }

    const handleShowSummary = () => {
        toggle();
    }

    const handleSubmitDoubt = (e) => {
        e.preventDefault();
        setLoading(true);
        const newDoubt = { title, description, lession_id: lession.id, user_id: user.id };
        api.post('/students/lession/doubt', newDoubt)
            .then(response => {
                console.log('/students/lession/doubt', response.data);
                setDoubts([...doubts, newDoubt]);
                setFilteredDoubts([...doubts, newDoubt]);
                setTitle('');
                setDescription('');
                setDoubt(null);
                setAddDoubt(false);
            })
            .catch(error => {
                console.error(error.response);
            })
            .finally(() => setLoading(false));
    };

    const handleSubmitAnswer = (e) => {
        e.preventDefault();
        setLoading(true);
        const newAnswer = { answer, doubt_id: doubt.id, user_id: user.id };
        api.post('/students/lession/answer', newAnswer)
            .then(response => {
                console.log('/students/lession/answer', response.data);
                const updatedAnswer = response.data.answer;
                const updatedDoubt = {
                    ...doubt,
                    answers: [...doubt.answers, updatedAnswer]
                };
                setDoubt(updatedDoubt);
                const updatedDoubts = doubts.map(d =>
                    d.id === doubt.id ? { ...d, answers: [...d.answers, updatedAnswer] } : d
                );
                setDoubts(updatedDoubts);
                setFilteredDoubts(updatedDoubts);
                setAnswer('');
            })
            .catch(error => {
                console.error(error.response);
            })
            .finally(() => setLoading(false));
    };

    const fetchDoubts = async () => {
        try {
            setLoadingForum(true);
            const response = await api.get(`/students/lessions/doubts/${idLession}`);
            const data = response.data.doubts;
            setDoubts(data);
            setFilteredDoubts(data);
            if (doubt) {
                const d = data.find(item => item.id = doubt.id);
                console.log("Founded D => ", d);
                setDoubt(d);
            }
        } catch (error) {
            console.error('Erro ao carregar dúvidas', error);
        } finally {
            setLoadingForum(false);
        }
    };

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        setFilteredDoubts(doubts.filter(doubt => doubt.title.toLowerCase().includes(term)));
    };

    useEffect(() => {
        fetchDoubts();
        const intervalId = setInterval(fetchDoubts, 10000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        api.get(`/students/lessions/${idLession}`)
            .then(response => {
                setSubject(response.data.subject);
                if (response.data.lession.summary.length > 0) {
                    setActive(response.data.lession.summary[0]);
                }
                setLession(response.data.lession);
                setSummary(response.data.lession.summary);
                setQuestions(response.data.quiz);
                setLastTopic(response.data.lession.summary[response.data.lession.summary.length - 1]);
            })
            .catch(error => {
                console.error(error.response.data);
            });
    }, [idLession]);

    dayjs.extend(relativeTime);

    return (
        <>
            <Header />
            {!active || !lession ?
                <div className='d-flex align-items-center justify-content-center h-100'><Spinner as="span" variant="primary" size="lg" role="status" aria-hidden="true" animation="grow" /></div> :
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className={showSummary ? 'col-12 col-md-8' : 'col-12'}>
                            <div className={showSummary ? '' : 'd-none'}>
                                <HeaderSubject subject={subject} />
                            </div>
                            <div className='title-box px-2 d-flex justify-content-between'>
                                <span className='title'>{lession.name} - {active.name}</span> <button className='btn btn-sm btn-success' onClick={handleShowSummary}>{showSummary ? 'Ocultar sumário' : 'Mostrar sumário'}</button>
                            </div>
                            {showMarks ?
                                <div className="pt-2">
                                    <div className='d-flex justify-content-between align-items-center' id='quiz_header'>
                                        <span className="">Visualizar nota do teste</span>
                                        <button className='btn btn-sm btn-success' onClick={handleReplyQuiz}>Refazer</button>
                                    </div>
                                    <div className='title-box mt-2 text-uppercase'>
                                        <h4 className='text-center'><b>{marks.school}</b></h4>
                                        <h5 className='text-center'>curso de {marks.course.degree} em {marks.course}</h5>
                                        <h6 className='text-center'>Resultados da Avaliação do programa online da disciplina de {marks.subject}</h6>
                                    </div>
                                    <br />
                                    <br />
                                    <div className='my-3'>
                                        <h5>Nome do Estudante: {user.name} {user.surname}</h5>
                                        <h5>Número de questões da Avalição: {marks.total}</h5>
                                        <h5>Número de questões correctas: {marks.corrects}</h5>
                                        <h5>Resultado de desempenho: {marks.marks}</h5>
                                    </div>
                                    <br />
                                    <br />
                                    <p className='text-center'><b>Data: {marks.date}</b></p>
                                </div>
                                :
                                <Fragment>
                                    {showQuiz ?
                                        <div className="pt-2">
                                            <div className='d-flex justify-content-between align-items-center' id='quiz_header'>
                                                <span className="">Realizar teste</span>
                                                {loading ?
                                                    <button type="button" className="btn btn-sm btn-success text-success">
                                                        <Spinner
                                                            as="span"
                                                            variant="white"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            animation="grow"
                                                        />
                                                        ...
                                                    </button> :
                                                    <button className='btn btn-sm btn-success text-white' onClick={handleSubmit}>Submeter</button>
                                                }
                                            </div>
                                            <div className={'quizView'}>
                                                {questions.map((question, index) => <div key={question.id} className="quiz">
                                                    <p className='question mb-1'>{++index}. {question.question}</p>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`quizOption${question.id}`}
                                                            id={`flexRadioDefault${question.id}1`}
                                                            value="A"
                                                            checked={userAnswers[question.id] === 'A'}
                                                            onChange={() => handleAnswerChange(question.id, 'A')}
                                                        />
                                                        <label className="form-check-label" htmlFor={`flexRadioDefault${question.id}1`}>{question.optionA}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`quizOption${question.id}`}
                                                            id={`flexRadioDefault${question.id}2`}
                                                            value="B"
                                                            checked={userAnswers[question.id] === 'B'}
                                                            onChange={() => handleAnswerChange(question.id, 'B')}
                                                        />
                                                        <label className="form-check-label" htmlFor={`flexRadioDefault${question.id}2`}>{question.optionB}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`quizOption${question.id}`}
                                                            id={`flexRadioDefault${question.id}3`}
                                                            value="C"
                                                            checked={userAnswers[question.id] === 'C'}
                                                            onChange={() => handleAnswerChange(question.id, 'C')}
                                                        />
                                                        <label className="form-check-label" htmlFor={`flexRadioDefault${question.id}3`}>{question.optionC}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`quizOption${question.id}`}
                                                            id={`flexRadioDefault${question.id}4`}
                                                            value="D"
                                                            checked={userAnswers[question.id] === 'D'}
                                                            onChange={() => handleAnswerChange(question.id, 'D')}
                                                        />
                                                        <label className="form-check-label" htmlFor={`flexRadioDefault${question.id}4`}>{question.optionD}</label>
                                                    </div>
                                                </div>)}
                                            </div>
                                        </div>
                                        :
                                        <Fragment>
                                            {['webm', 'mp4', 'mp3'].includes(active.type) ?
                                                <ReactPlayer width="100%" height="400px" controls={true} url={active.file_path} />
                                                :
                                                <iframe width="100%" height="400px" src={`data:application/pdf;base64,${active.file_path}`} title={active.name} frameborder="0"></iframe>
                                            }
                                            {doubt ?
                                                <Fragment>
                                                    <hr className='my-2' />
                                                    <div className="pb-5">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className='col-12'>
                                                                    <div className="d-flex justify-content-between align-items-end border-bottom pb-2 mb-2">
                                                                        <button className='btn btn-sm btn-outline-dark d-flex align-items-end gap-2 px-3' onClick={() => setDoubt(null)}><FaLongArrowAltLeft /> <small>Voltar a todas perguntas</small></button>
                                                                    </div>
                                                                    <div className='d-flex gap-2'>
                                                                        <Image src={defaultProfile} className='' alt='User Image' width={30} height={30} />
                                                                        <div>
                                                                            <span className='fw-semibold'>{doubt.title}</span>
                                                                            <p className='mb-2'>{doubt.description}</p>
                                                                            <small className='text-muted'>{doubt.user ? `${doubt.user.name} ${doubt.user.surname} - ${doubt.user.role}` : ''} - {doubt.created_at ? dayjs(doubt.created_at).fromNow() : 'Data desconhecida'}</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between align-items-end border-bottom pb-2">
                                                                        <p className='fw-semibold mb-0'><small>Respostas {`(${doubt.answers?.length || 0})`}</small></p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    {doubt.answers?.map((item, index) => <div key={index} className='d-flex align-items-start gap-2 border-bottom mt-2 pb-1'>
                                                                        <Image src={defaultProfile} className='' alt='User Image' width={30} height={30} />
                                                                        <div className='w-100'>
                                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                                <small className='fw-semibold'>{item.user ? `${item.user.name} ${item.user.surname} - ${item.user.role}` : ''}</small>
                                                                                <small className='text-muted'>{item.created_at ? dayjs(item.created_at).fromNow() : 'Data desconhecida'}</small>
                                                                            </div>
                                                                            <p className='mb-2'>{item.answer}</p>
                                                                        </div>
                                                                    </div>)}
                                                                    <div className='d-flex align-items-start gap-2 border-bottom mt-2 pb-2'>
                                                                        <Image src={defaultProfile} className='' alt='User Image' width={30} height={30} />
                                                                        <div className='w-100 d-flex'>
                                                                            <input
                                                                                type="text"
                                                                                id="title"
                                                                                value={answer}
                                                                                maxLength={255}
                                                                                aria-label="adicionar resposta"
                                                                                placeholder="Adicionar resposta"
                                                                                className='form-control form-control-sm'
                                                                                onChange={(e) => setAnswer(e.target.value)}
                                                                                required
                                                                            />
                                                                            {loading ?
                                                                                <button type="button" className="btn btn-dark d-flex align-items-center justify-content-center">
                                                                                    <Spinner
                                                                                        as="span"
                                                                                        variant="white"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                        animation="grow"
                                                                                    />
                                                                                </button> :
                                                                                <button type="button" className='btn btn-dark d-flex align-items-center justify-content-center' onClick={handleSubmitAnswer}><IoMdSend /></button>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment> :
                                                <Fragment>
                                                    {addDoubt ?
                                                        <Fragment>
                                                            <hr className='my-2' />
                                                            <div className="pb-5">
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className='col-12'>
                                                                            <div className="d-flex justify-content-between align-items-end border-bottom pb-2 mb-2">
                                                                                <button className='btn btn-sm btn-outline-dark d-flex align-items-end gap-2 px-3' onClick={() => setAddDoubt(false)}><FaLongArrowAltLeft /> <small>Voltar a todas perguntas</small></button>
                                                                            </div>
                                                                            <form>
                                                                                <div className='mb-3'>
                                                                                    <label htmlFor="title" className='mb-1'>Pergunta</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        id="title"
                                                                                        className='form-control form-control-sm'
                                                                                        value={title}
                                                                                        onChange={(e) => setTitle(e.target.value)}
                                                                                        maxLength={255}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className='mb-3'>
                                                                                    <label htmlFor="description" className='mb-1'>Descrição (Opcional)</label>
                                                                                    <textarea
                                                                                        className='form-control form-control-sm'
                                                                                        id="description"
                                                                                        value={description}
                                                                                        onChange={(e) => setDescription(e.target.value)}
                                                                                    />
                                                                                </div>
                                                                                {loading ?
                                                                                    <button type="button" className="btn btn-sm btn-success text-success">
                                                                                        <Spinner
                                                                                            as="span"
                                                                                            variant="white"
                                                                                            size="sm"
                                                                                            role="status"
                                                                                            aria-hidden="true"
                                                                                            animation="grow"
                                                                                        />
                                                                                    </button> :
                                                                                    <button type="button" className='btn btn-sm btn-success text-white' onClick={handleSubmitDoubt}>Publicar</button>
                                                                                }
                                                                            </form>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment> :
                                                        <Fragment>
                                                            <hr className='my-2' />
                                                            <div className="pb-5">
                                                                <div className="row">
                                                                    <div className='col-12 col-md-6'>
                                                                        <div className="input-group mb-2">
                                                                            <input type="text"
                                                                                className="form-control form-control-sm"
                                                                                placeholder="Pesquisar perguntas da aula..."
                                                                                aria-label="Pesquisar perguntas"
                                                                                aria-describedby="button-addon2"
                                                                                value={searchTerm}
                                                                                onChange={handleSearch}
                                                                            />
                                                                            <button className="btn btn-dark d-flex align-items-center justify-content-center" type="button" id="button-addon2"><FaSearch /></button>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-12'>
                                                                        <div className="d-flex justify-content-between align-items-end border-bottom pb-2">
                                                                            <p className='fw-semibold mb-0'>
                                                                                <small>Perguntas encontradas {loadingForum ?
                                                                                    <Spinner
                                                                                        as="span"
                                                                                        variant="secondary"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                        animation="grow"
                                                                                    /> : `(${doubts.length})`}
                                                                                </small>
                                                                            </p>
                                                                            <button className='d-flex align-items-center gap-1' onClick={() => setAddDoubt(true)}><TbMessage2Plus /> Nova Pergunta</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            {doubts.map((item, index) => <div key={index} className='d-flex align-items-start justify-content-between border-bottom mt-2 pb-1'>
                                                                                <div className='d-flex gap-2'>
                                                                                    <Image src={defaultProfile} className='' alt='User Image' width={30} height={30} />
                                                                                    <div>
                                                                                        <span className='fw-semibold'>{item.title}</span>
                                                                                        <p className='mb-2'>{item.description}</p>
                                                                                        <small className='text-muted'>{item.user ? `${item.user.role} ${item.user.name} ${item.user.surname}` : ''} - {item.created_at ? dayjs(item.created_at).fromNow() : 'Data desconhecida'}</small>
                                                                                    </div>
                                                                                </div>
                                                                                <button className='d-flex align-items-center gap-1 text-dark' onClick={() => setDoubt(item)} style={{ backgroundColor: "white", border: "none" }}>
                                                                                    <span className='fw-semibold'>{`(${item.answers?.length || 0})`}</span>
                                                                                    <span className='fw-semibold'><TbMessage2Check /></span>
                                                                                </button>
                                                                            </div>)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                        </div>
                        <div className={showSummary ? 'col-12 col-md-4' : 'col-12 d-none'}>
                            <div id='summry' className='pb-1'>
                                <span>Sumário</span>
                            </div>
                            <ul className='' id='lessions'>
                                {summary.map((value) => <li
                                    id={value.id}
                                    className={active?.id === value.id && !showQuiz && !showMarks ? 'active' : ''}
                                    onClick={() => showTopic(value)}
                                    key={value.id}>{value.name}</li>
                                )}
                                <li
                                    onClick={handleShowQuiz}
                                    className={showQuiz || showMarks ? 'active' : ''}
                                >
                                    {lession.status === 'Concluída' ? 'Visualizar Nota' : 'Realizar Teste'}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Lession;