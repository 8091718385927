import axios from "axios";
export const apiUrl = process.env.REACT_APP_API_URL;


const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`
});

api.interceptors.request.use(async config => {
  const token = localStorage.getItem('@techworks-Token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const storedUser = localStorage.getItem("@techworks-User");
  const user = JSON.parse(storedUser);
  const id = user?.id;
  
  if (id) {
    if (config.method === 'get' || config.method === 'delete') {
      config.params = config.params || {};
      config.params.id = id;
    } else {
      config.data = config.data || {};
      config.data.id = id;
    }
  }

  return config;
});

export default api;
